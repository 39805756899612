import * as React from "react";
import { Link } from "gatsby";

import {
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { CheckCircleIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const How = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color={colorMode === "light" ? "gray.500" : "whiteAlpha.900"}
            _hover={
              colorMode === "light"
                ? { color: "#333" }
                : { color: "whiteAlpha.700" }
            }
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>
        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l, #BDCF4B , #CF9B23)"
          bgClip="text"
        >
          Comment se transformer en une Product First Company ?
        </Text>

        <Text fontSize="xl" my="40px">
          PARSK propose une méthodologie d’accompagnement qui s’appuie sur un
          framework, pour rendre opérationnelle et exécutable l’approche Product
          First.
        </Text>
        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            mb="20px"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            Un framework
          </Heading>

          <List spacing="40px" fontSize="xl">
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              <b>
                Formalisation de la vision à 3-5 ans en choix structurants
              </b>{" "}
              tiré de la Raison d'être et/ou du Plan stratégique ⇒ Définition de
              la Stratégie Produit.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              <b>
                Définition des grands principes Produit qui doivent régir
                l’expérience utilisateur
              </b>{" "}
              et en garantir son impact réel ⇒ Spécification de la Customer
              Experience
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              <b>
                Mise en place d'un système de gouvernance et de suivi
                d’objectifs
              </b>{" "}
              pour suivre et évaluer l’impact Produit ⇒ Formalisation des OKR
            </ListItem>
          </List>

          <Text fontSize="xl" my="4">
            {/* Les outils : audits, benchmarks, interviews individuels, ateliers
            collaboratifs, mapping produits/services (outils), revue de
            l'organisation et des interdépendances (process), conseil
            stratégique, accompagnement opérationnel, co-développement…
            <br />
            <br /> */}
            <br /> <br />
            Ce framework est le{" "}
            <b>
              prérequis dont découlera l'expérience Collaborateurs et
              l'expérience Clients.
            </b>
          </Text>

          <List spacing="20px" fontSize="xl">
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Co-construction de la stratégie d’une entreprise ou d’une
              organisation
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Co-construction d’une feuille de route de transformation
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Réévaluation de la raison d’être et de la roadmap opérationnelle
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Refonte de l’expérience client
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Développement du leadership individuel et collectif
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Èvolution de la culture d’entreprise vers plus de collaboratif
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="#CF9B23" />
              Accompagnement de projets innovants / intrapreneuriat
            </ListItem>
          </List>
        </Box>

        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            Un accompagnement
          </Heading>
          <Text fontSize="xl" my="4">
            La transformation est propre à toute entreprise et ne peut être
            dupliquée. PARSK propose une approche tarifaire sur mesure qui
            tiendra compte de la maturité de l’entreprise qu'elle accompagne, de
            l’ambition visée et du chemin de réflexion déjà parcouru. PARSK
            évalue le niveau d'accompagnement nécessaire et dimensionne son
            offre en fonction de chaque besoin.
          </Text>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default How;
